import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const CircleProgress = props => {
  const {
    size, // Size of the enclosing square
    strokeWidth,
    percentage,
  } = props;

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (size - strokeWidth) / 2;

  // Enclose circle in a circumscribing square
  const viewBox = `0 0 ${size} ${size}`;

  // Arc length at 100% coverage is the circle circumference
  const dashArray = 2 * Math.PI * radius;

  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      className="circle-progress"
      style={dashOffset === 0 ? { opacity: 0 } : {}}
    >
      <circle className="background" cx={size / 2} cy={size / 2} r={radius} strokeWidth="2pt" />
      <circle
        className="progress"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth="5px"
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Start progress marker at 12 O'Clock
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
      />
    </svg>
  );
};

CircleProgress.propTypes = {
  strokeWidth: PropTypes.string,
  size: PropTypes.number,
  percentage: PropTypes.number,
};

CircleProgress.defaultProps = {
  strokeWidth: '6',
  size: 200,
  percentage: 55,
};

export default CircleProgress;
