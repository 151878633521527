import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const DotsProgress = props => {
  const { dots, currentDot, radius, stroke, margin, onDotClick } = props;

  const diameter = radius * 2;

  return (
    <svg
      className="dots-progress"
      style={{
        width: `${diameter + stroke * 2}px`,
        height: `${(diameter + stroke) * dots.length + margin * dots.length - 1}px`,
      }}
    >
      {dots.map((dot, index) => (
        <circle
          key={index}
          className="dot"
          cx={radius + stroke}
          cy={index * diameter + index * margin + radius + stroke}
          r={index <= currentDot ? radius : radius - stroke / 2}
          // fill={index === currentDot
          //   ? '#FFF'
          //   : 'transparent'
          // }
          fill="#FFF"
          fillOpacity={index === currentDot ? 1 : 0.3}
          stroke={index === currentDot ? 'none' : '#FFF'}
          strokeWidth={`${stroke}px`}
          strokeOpacity={index === currentDot ? 1 : 0.7}
          onClick={event => {
            onDotClick(index);
          }}
        />
      ))}
    </svg>
  );
};

DotsProgress.propTypes = {
  dots: PropTypes.array.isRequired,
  currentDot: PropTypes.number.isRequired,
  onDotClick: PropTypes.func,
  radius: PropTypes.number,
  stroke: PropTypes.number,
  margin: PropTypes.number,
};

DotsProgress.defaultProps = {
  onDotClick: undefined,
  radius: 7,
  stroke: 0,
  margin: 18,
};

export default DotsProgress;
