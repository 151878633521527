export const initialStyle = {
  background: {
    opacity: 0,
    backgroundPosition: '50% 50px',
    transition: 'opacity 1s ease, background-position .5s ease',
  },
  description: {
    opacity: 0,
    bottom: '-100px',
  },
  button: {
    opacity: 0,
    marginBottom: '15px',
    bottom: '-100px',
  },
};

export const enterStyle = {
  background: {
    opacity: 1,
    backgroundPosition: '50% 0px',
    transition: 'opacity 1s ease, background-position .5s ease',
  },
  titleLine1: {
    animation: 'light-up 2s ease .5s',
    WebkitAnimation: 'light-up 2s ease .5s',
  },
  titleLine2: {
    animation: 'light-up 2s ease .7s',
    WebkitAnimation: 'light-up 2s ease .7s',
  },
  titleLine3: {
    animation: 'light-up 2s ease .9s',
    WebkitAnimation: 'light-up 2s ease .9s',
  },
  titleLine4: {
    animation: 'light-up 2s ease 1.1s',
    WebkitAnimation: 'light-up 2s ease 1.1s',
  },
  description: {
    opacity: 1,
    bottom: '0px',
    transition: 'opacity 1s ease, bottom 1s ease',
    WebkitAnimation: 'opacity 1s ease, bottom 1s ease',
  },
  button: {
    opacity: 1,
    bottom: '0px',
    marginBottom: '15px',
    transition: 'opacity 1s ease .5s, margin-top 1s ease .5s',
  },
};

export const exitStyle = {
  background: {
    opacity: 0,
    backgroundPosition: '50% -100px',
    transition: 'opacity 1s ease, background-position .8s ease',
  },
};
