import React from 'react';
import { Segment, Image, Divider, Button } from 'semantic-ui-react';
import './style.css';

const ServiceMessage = props => {
  return (
    <div className="service-message">
      <Segment basic textAlign="center">
        <Divider hidden />
        <Image src={props.logo} size="small" centered />
        <Divider hidden />
        <h4>Oops. Looks like there are connection problems.</h4>
        <p>Check your internet connection or retry later.</p>
        <Button content="Refresh" icon="refresh" onClick={() => window.location.reload()} />
      </Segment>
    </div>
  );
};

export default ServiceMessage;
