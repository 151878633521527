import { SET_SELECTED_CITY } from '../actions/city.actions';

export default (state = '', { type, data }) => {
  switch (type) {
    case SET_SELECTED_CITY:
      return data;
    default:
      return state;
  }
};
