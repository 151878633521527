import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { initialStyle, enterStyle, exitStyle } from './animation';

import './slide.css';
import messages from '../../../messages';

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialStyle,
    };
  }

  componentDidMount() {
    // The entering animation is delayed by 400ms
    // after the component mount
    setTimeout(() => {
      this.setState({
        ...enterStyle,
      });
    }, 400);
  }

  componentDidUpdate(prevProps) {
    const { onGoingSlideChange } = this.props;

    if (prevProps.onGoingSlideChange !== onGoingSlideChange && onGoingSlideChange) {
      this.setState({
        ...exitStyle,
      });
    }
  }

  render() {
    const { background, titleLine1, titleLine2, titleLine3, description, button } = this.state;
    const {
      intl: { formatMessage },
      config,
    } = this.props;

    return (
      <div style={background} className="slide background-6">
        <div className="content">
          <div className="title">
            <div style={titleLine1}>EFFEKTIVES</div>
            <div style={titleLine2}>ARBEITEN OHNE</div>
            <div style={titleLine3}>ZEITVERLUST</div>
          </div>
          <div className="description" style={description}>
            <p>
              Angestellte verlieren jedes Jahr rund eine Woche Arbeitszeit durch langsame
              Internetverbindungen. Die Investition in GlasfaserDirekt zahlt sich auf Anhieb aus und
              bringt dir noch viel mehr Vorteile, als bloß schnelleres Breitband-Internet. Mit
              GlasfaserDirekt wird von Kartenzahlungen bis hin zu den Bildern der Videoüberwachung
              in deinem Geschäft alles augenblicklich hochgeladen. Die Punkt-zu-Punkt-Leitungen von
              GlasfaserDirekt machen dein Unternehmen sicherer und helfen, es vor Cyber Crimes zu
              schützen.
            </p>
          </div>
          <div style={button} className="buttons">
            <Link to={`${config.base_url}business`}>
              <Button style={button} className="primary">
                {formatMessage(messages.learn_more)}
              </Button>
            </Link>
            <Link to={`${config.base_url}coverage`}>
              <Button className="gradient">
                {formatMessage(messages.navigation_get_connected)}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  onGoingSlideChange: PropTypes.bool,
};

Slide.defaultProps = {
  onGoingSlideChange: false,
};

export default injectIntl(Slide);
