import React, { Component } from 'react';
import { Button, Checkbox, Dimmer, Divider, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const injectGAScript = () => {
  const config = JSON.parse(sessionStorage.getItem('config'));
  // Store consent only if is not previously given
  if (!localStorage.getItem('consent')) {
    // TODO for GDPR compliance consent should be stored into DB
    // with an incremental logic 'cause it should be denied and given
    // multiple times from the same user/anonymous and legals has to be able to know exactly when
    localStorage.setItem('consent', 'Given on ' + moment(Date.now()).format('LLL'));
  }

  // Create GTM HEAD script
  const GTMHeadScript = document.createElement('script');
  /* eslint-disable quotes */
  GTMHeadScript.innerText =
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
    `})(window,document,'script','dataLayer','${config.google_tm_container_id}');`;
  /* eslint-enable quote */

  // Create GTM BODY script elements
  const GTMBodyScript = document.createElement('noscript');
  const GTMiFrame = document.createElement('iframe');

  const iFrameWidth = document.createAttribute('width');
  const iFrameHeight = document.createAttribute('height');
  iFrameWidth.value = '0';
  iFrameHeight.value = '0';

  // Apply attributes to the <iframe>
  GTMiFrame.src = `https://www.googletagmanager.com/ns.html?id=${config.google_tm_container_id}`;
  GTMiFrame.setAttributeNode(iFrameWidth);
  GTMiFrame.setAttributeNode(iFrameHeight);
  GTMiFrame.style.display = 'none';
  GTMiFrame.style.visibility = 'hidden';

  // <iframe> is built so nest it into <noscript> element
  GTMBodyScript.appendChild(GTMiFrame);

  // Finally append scripts to the DOM
  document.head.appendChild(GTMHeadScript);
  document.body.appendChild(GTMBodyScript);
};

class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { gtmcookie: undefined, functionalChecked: false, statisticalChecked: false };

    this.injectScript = this.injectScript.bind(this);
    this.closeBanner = this.closeBanner.bind(this);
  }

  componentDidMount() {
    // Add Google Tag Manager scripts if consent was previously given
    if (localStorage.getItem('consent')) {
      this.injectScript();
    } else {
      this.setState({ gtmcookie: false });
    }
  }

  injectScript() {
    injectGAScript();
    this.setState({ gtmcookie: true, functionalChecked: true, statisticalChecked: true });
  }

  handleFunctionalCheckbox() {
    if (this.state.functionalChecked) {
      this.setState({ functionalChecked: false });
    } else {
      this.setState({ functionalChecked: true });
    }
  }

  handleStatisticalCheckbox() {
    if (this.state.statisticalChecked) {
      this.setState({ statisticalChecked: false });
    } else {
      this.setState({ statisticalChecked: true });
    }
  }

  closeBanner() {
    this.setState({ gtmcookie: true });
  }

  render() {
    const { baseURL } = this.props;

    return (
      <Dimmer active={!this.state.gtmcookie} page>
        <h3>Cookies bei LandConnect</h3>
        <div
          style={{
            maxWidth: '450px',
            textAlign: 'justify',
            marginBottom: '20px',
            lineHeight: '20px',
          }}
        >
          Wir verwenden Cookies, um effizient zwischen den Seiten zu navigieren, Ihre Einstellungen
          zu speichern und die Benutzererfahrung im Allgemeinen zu verbessern. Wenn Sie auf OK
          klicken, stimmen Sie zu, dass LandConnect Cookies auf Ihrem Gerät platziert. Weitere
          Informationen zu den von LandConnect verwendeten Cookies finden Sie{' '}
          <Link
            to={`${baseURL}cookie-policy`}
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            hier
          </Link>
          .
        </div>
        <Divider hidden />
        <Grid columns={2}>
          <GridRow>
            <GridColumn style={{ textAlign: 'left' }}>
              <label htmlFor="functionalToggel">Essenziell</label>
            </GridColumn>
            <GridColumn style={{ textAlign: 'right' }}>
              <Checkbox
                id={'functionalToggel'}
                toggle
                checked={this.state.functionalChecked}
                onClick={() => this.handleFunctionalCheckbox()}
              ></Checkbox>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn style={{ textAlign: 'left' }}>
              <label htmlFor="statisticalToggel">Functionell</label>
            </GridColumn>
            <GridColumn style={{ textAlign: 'right' }}>
              <Checkbox
                id={'statisticalToggel'}
                toggle
                checked={this.state.statisticalChecked}
                onClick={() => this.handleStatisticalCheckbox()}
              ></Checkbox>
            </GridColumn>
          </GridRow>
        </Grid>
        <Divider hidden />
        {this.state.functionalChecked || this.state.statisticalChecked ? (
          <Button
            style={{ fontWeight: 'normal', display: 'block', margin: '0 auto' }}
            onClick={
              this.state.functionalChecked && !this.state.statisticalChecked
                ? this.closeBanner
                : this.injectScript
            }
          >
            Nur mit Ihren eigenen Einstellungen fort
          </Button>
        ) : (
          <Button
            style={{ fontWeight: 'normal', display: 'block', margin: '0 auto' }}
            onClick={this.closeBanner}
          >
            Nur mit notwendigen Cookies fortfahren
          </Button>
        )}
        <Divider hidden />
        <Button
          style={{ fontWeight: 'normal', display: 'block', margin: '0 auto' }}
          onClick={this.injectScript}
          primary
        >
          Akzeptieren Sie empfohlene Cookies
        </Button>
      </Dimmer>
    );
  }
}

export { CookieBanner, injectGAScript };
