import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const LoadingSpinner = () => {
  return (
    <Dimmer className="dimmer-fixed" active inverted>
      <Loader>Laden</Loader>
    </Dimmer>
  );
};

export default LoadingSpinner;
