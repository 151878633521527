import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { useResponsive } from 'portal-components';
import { Button, Divider } from 'semantic-ui-react';

import messages from '../../messages';
import contentData from '../../content/pages/residential.json';

const ResidentialPage = props => {
  const { slides } = contentData;
  const {
    config,
    button,
    intl: { formatMessage },
  } = props;

  // Get window size
  const { isMobile } = useResponsive();

  return (
    <div className="residential">
      {slides.map((slide, index) => {
        const className = `slide ${
          // Even slides are dark while odd ones are light
          index % 2 === 0 ? 'dark' : 'light'
        } slide${index + 1}`;

        // Set the background according to the window size
        const style = {
          backgroundImage: `url(${isMobile ? slide.background_mobile : slide.background})`,
        };

        return (
          <div key={`slide${index}`} style={style} className={className}>
            <div className="content">
              <div className="title">
                <h2>{slide.title}</h2>
              </div>
              <div className="description">
                <ReactMarkdown
                  children={slide.description}
                  components={{
                    // Inject React Router Link components to internal app links
                    a({ className, children, href }) {
                      return href[0] === '/' ? (
                        <Link to={`${config.base_url}${href.slice(1)}`}>{children}</Link>
                      ) : (
                        <a
                          href={href}
                          className={className}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {children}
                        </a>
                      );
                    },
                  }}
                />
                <Divider hidden />
                <div style={button} className="buttons">
                  {slide.check_my_availability_button && (
                    <Link to={`${config.base_url}coverage`}>
                      <Button className="gradient">
                        {formatMessage(messages.navigation_get_connected)}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(ResidentialPage);
