import React from 'react';
import _ from 'lodash';
import Slide1 from './slides/Slide1';
import Slide2 from './slides/Slide2';
import Slide3 from './slides/Slide3';
import Slide4 from './slides/Slide4';
import Slide5 from './slides/Slide5';
import Slide6 from './slides/Slide6';
import { responsive } from 'portal-components';

import { Dots as Progress } from '../../components/Progress';
import chevron from '../../assets/chevron-down.svg';
import './style.css';

// This value specify which is the maximum duration (in ms)
// an outro animation can go on in order to conclude without
const outroAnimationDuration = 400;

class Home extends React.Component {
  constructor(props) {
    super(props);

    const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6];
    const currentSlideIndex = 0;

    this.state = {
      slides,
      button: {},
      currentSlideIndex,
      CurrentSlide: slides[currentSlideIndex],
    };

    this.handleGoToSlide = this.handleGoToSlide.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.alignMobileButton = this.alignMobileButton.bind(this);

    // Debounce prevents multiple slide changes with just one scroll gesture
    this.handleScroll = _.debounce(this.handleScroll, 300, { leading: true, trailing: false }).bind(
      this,
    );

    this.handleKeyDown = _.debounce(this.handleKeyDown, 300, {
      leading: true,
      trailing: false,
    }).bind(this);
  }

  componentDidMount() {
    // This CSS class is added to the body in order to prevent the
    // page to move on scroll when content exactly fit to viewport
    document.body.classList.add('overflow-hidden');
    // Focus on mount is needed to let scroll gestures work on the .home div
    this.homepage.focus();
    // Align the arrow button if on mobile devices
    this.alignMobileButton();
  }

  componentWillUnmount() {
    // This CSS class is removed on unmount in order to leave the
    // standard behaviour on other pages that does not need 'overflow-hidden'
    document.body.classList.remove('overflow-hidden');
  }

  componentDidUpdate(prevProps, prevStates) {
    const { homepageFirstSlide, updateHomepageFirstSlide } = this.props;
    const { onGoingSlideChange, currentSlideIndex, CurrentSlide, slides } = this.state;

    if (currentSlideIndex !== 0 && homepageFirstSlide) {
      updateHomepageFirstSlide();
    }

    // A request to change slide has been made.
    // In order to let the current slide make its outro animation,
    // the slide change is the delayed by 1000ms.
    if (prevStates.onGoingSlideChange !== onGoingSlideChange) {
      const NewCurrentSlide =
        prevStates.currentSlideIndex !== currentSlideIndex
          ? slides[currentSlideIndex]
          : CurrentSlide;

      setTimeout(() => {
        this.setState({
          onGoingSlideChange: false,
          CurrentSlide: NewCurrentSlide,
        });
      }, outroAnimationDuration);

      // Show the footer on the last slide
      if (currentSlideIndex === slides.length - 1) {
        setTimeout(() => {
          // This CSS class is temporarely removed in order to allow users to
          // scroll down and see the footer
          document.body.classList.remove('overflow-hidden');
        }, 2000);
      } else {
        document.body.classList.add('overflow-hidden');
      }
    }
  }

  alignMobileButton() {
    if (window.innerWidth < 768) {
      // If there isn't the TOP property already
      !this.state.button.top &&
        // Take the first slide check-availability button element
        document.querySelector('.postcode-home-btn') &&
        // And put his boundingRect on the state
        this.setState({
          button: document.querySelector('.postcode-home-btn').getBoundingClientRect(),
        });
    }
  }

  handleScroll(event) {
    const { slides, currentSlideIndex } = this.state;
    // The deltaY property returns a positive value when scrolling down,
    // and a negative value when scrolling up, otherwise 0.
    const slideDirection = Math.sign(event.deltaY);
    let newCurrentSlideIndex;

    if (slideDirection > 0 && currentSlideIndex + 1 < slides.length) {
      // If slide direction is positive (going down) and there are
      // slides available, currentSlideIndex is incremented
      newCurrentSlideIndex = currentSlideIndex + 1;
    } else if (slideDirection < 0 && currentSlideIndex - 1 >= 0) {
      // If slide direction is negative (going up) and there are
      // slides available, currentSlideIndex is decremented
      newCurrentSlideIndex = currentSlideIndex - 1;
    }

    // Check if the page has not be scrolled. This needs to be done in order to prevent
    // slide change when the last slide is reached and the footer is shown.
    if ((document.documentElement || document.body.parentNode || document.body).scrollTop === 0) {
      if (newCurrentSlideIndex !== undefined) {
        this.setState({
          currentSlideIndex: newCurrentSlideIndex,
          onGoingSlideChange: true,
        });
      }
    }
  }

  handleKeyDown(event) {
    const { slides, currentSlideIndex } = this.state;
    let newCurrentSlideIndex;

    if (event.keyCode === 40 && currentSlideIndex + 1 < slides.length) {
      // If the key pressed is 'Arrow Down' and there are
      // slides available, currentSlideIndex is incremented
      newCurrentSlideIndex = currentSlideIndex + 1;
    } else if (event.keyCode === 38 && currentSlideIndex - 1 >= 0) {
      // If the key pressed is 'Arrow Up' and there are
      // slides available, currentSlideIndex is decremented
      newCurrentSlideIndex = currentSlideIndex - 1;
    }

    // Check if the page has not be scrolled. This needs to be done in order to prevent
    // slide change when the last slide is reached and the footer is shown.
    if ((document.documentElement || document.body.parentNode || document.body).scrollTop === 0) {
      if (newCurrentSlideIndex !== undefined) {
        this.setState({
          currentSlideIndex: newCurrentSlideIndex,
          onGoingSlideChange: true,
        });
      }
    }
  }

  handleTouchStart(event) {
    this.setState({
      touchStartY: event.touches[0].clientY,
    });
  }

  handleTouchEnd(event) {
    const { slides, currentSlideIndex, touchStartY } = this.state;
    let newCurrentSlideIndex;

    const touchEndY = event.changedTouches[0].clientY;

    if (touchStartY > touchEndY && currentSlideIndex + 1 < slides.length) {
      // If slide direction is positive (going down) and there are
      // slides available, currentSlideIndex is incremented
      newCurrentSlideIndex = currentSlideIndex + 1;
    } else if (touchStartY < touchEndY && currentSlideIndex - 1 >= 0) {
      // If slide direction is negative (going up) and there are
      // slides available, currentSlideIndex is decremented
      newCurrentSlideIndex = currentSlideIndex - 1;
    }

    // Check if the page has not be scrolled. This needs to be done in order to prevent
    // slide change when the last slide is reached and the footer is shown.
    if ((document.documentElement || document.body.parentNode || document.body).scrollTop === 0) {
      if (newCurrentSlideIndex !== undefined) {
        this.setState({
          currentSlideIndex: newCurrentSlideIndex,
          onGoingSlideChange: true,
        });
      }
    }
  }

  handleGoToSlide(newSlideIndex) {
    const { currentSlideIndex } = this.state;

    if (newSlideIndex !== currentSlideIndex) {
      // If the page has been scrolled down to the footer, first scrool to top
      if (
        !((document.documentElement || document.body.parentNode || document.body).scrollTop === 0)
      ) {
        // Smoothly scroll to top
        const scrollToTop = () => {
          if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
            window.scrollBy(0, -12);
            requestAnimationFrame(scrollToTop);
          } else {
            this.setState({
              currentSlideIndex: newSlideIndex,
              onGoingSlideChange: true,
            });
          }
        };

        scrollToTop();
      } else {
        this.setState({
          currentSlideIndex: newSlideIndex,
          onGoingSlideChange: true,
        });
      }
    }
  }

  render() {
    const { config } = this.props;
    const { CurrentSlide, currentSlideIndex, onGoingSlideChange, slides, button } = this.state;

    return (
      <div
        className="home"
        tabIndex="0"
        ref={ref => {
          this.homepage = ref;
        }}
        // Desktop events
        onWheel={this.handleScroll}
        onKeyDown={this.handleKeyDown}
        // Mobile events
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
      >
        <CurrentSlide config={config} onGoingSlideChange={onGoingSlideChange} />
        {window.innerWidth >= responsive.onlyTablet.minWidth && (
          <Progress
            dots={slides}
            currentDot={currentSlideIndex}
            onDotClick={this.handleGoToSlide}
          />
        )}
        {currentSlideIndex < slides.length - 1 && (
          <img
            onClick={() => this.handleGoToSlide(currentSlideIndex + 1)}
            style={button.top && { top: button.top }}
            className="down-chevron-button"
            src={chevron}
            alt=""
          />
        )}
      </div>
    );
  }
}

export default Home;
