import React, { useState } from 'react';
import useFetch from 'use-http';
import { Link } from 'react-router-dom';
import colorThief from 'colorthief';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { Icon, Loader, Button, Divider, Select } from 'semantic-ui-react';

import './style.css';
import messages from '../../messages';
import chevron from '../../assets/chevron-down.svg';
import { setSelectedCity } from '../../actions/city.actions';

// FIXME: remove once the class component below
// will be translated to functional component
const FunctionalWrapper = props => {
  const { config } = props;

  // SET the local state
  const [serviceProviders, setServiceProviders] = useState(undefined);

  // INITIALIZE fetch to perform requests
  const { get, response } = useFetch(config.api, { cachePolicy: 'no-cache' });

  // GET default payment
  const getServiceProviders = async targetCity => {
    await get(`/report/service_provider_summary${targetCity ? `?object_group=${targetCity}` : ''}`);

    setServiceProviders(response.ok ? response.data.data : []);
  };

  return (
    <OpenAccessPage
      {...props}
      serviceProviders={serviceProviders}
      setServiceProviders={setServiceProviders}
      getServiceProviders={getServiceProviders}
    />
  );
};

class OpenAccessPage extends React.Component {
  constructor(props) {
    super(props);

    const { selectedCity } = props;

    this.state = {
      colorList: {},
      customerType: 'Residential',
      selectedCity:
        selectedCity === 'Haager Lies' ||
        selectedCity === 'Haager Lies2' ||
        selectedCity === 'Haager Lies3' ||
        selectedCity === 'Haager Lies4' ||
        selectedCity === 'Haager Lies5'
          ? 'Haager Lies'
          : selectedCity,
    };

    this.colorPick = this.colorPick.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.toggleServiceProvider = this.toggleServiceProvider.bind(this);
    this.handleCustomerTypeChange = this.handleCustomerTypeChange.bind(this);
  }

  componentDidMount() {
    const { selectedCity } = this.state;
    const { cities, getServiceProviders } = this.props;
    const targetCity = cities.find(city => city.name === selectedCity);

    if (targetCity) {
      getServiceProviders(targetCity.id);
    }
  }

  handleCityChange(event, data) {
    const { cities, getServiceProviders, setSelectedCity } = this.props;
    const city = data.value;
    const targetCity = cities.find(el => el.name === city);

    setSelectedCity(city);

    if (targetCity) {
      getServiceProviders(targetCity.id);
    }
  }

  handleCustomerTypeChange(customerType) {
    this.setState({ customerType });
  }

  isoToLanguage(iso) {
    switch (iso) {
      case 'en':
        return 'English';
      case 'de':
        return 'German';
      default:
    }
  }

  colorPick(id) {
    const colorT = new colorThief();
    const img = this[id];

    if (img && img.width > 0) {
      const [[r, g, b]] = colorT.getPalette(img, 2, 1);
      this.setState({
        colorList: {
          ...this.state.colorList,
          [id]: `rgba(${r}, ${g}, ${b}, 1)`,
        },
      });
    }
  }

  scrollDown() {
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  }

  formatURL(url, n) {
    // cleanedUrlString => remove 'http://', 'https://' and last '/' from url string
    const cleanedUrlString = url.replace(/(^\w+:|^)\/\//, '').replace(/\/$/, '');
    // return url string with ... if cleanedUrlString > n
    return cleanedUrlString.length > n
      ? `${cleanedUrlString.substr(0, n - 1)}... `
      : cleanedUrlString;
  }

  toggleServiceProvider(customerType, id) {
    const { serviceProviders, setServiceProviders } = this.props;

    setServiceProviders({
      ...serviceProviders,
      [customerType]: serviceProviders[customerType].map(sp =>
        sp.id === id ? { ...sp, open: !sp.open } : sp,
      ),
    });
  }

  render() {
    const { customerType, colorList, selectedCity } = this.state;
    const {
      config,
      cities,
      userlang,
      selectedPremises,
      serviceProviders,
      intl: { formatMessage },
    } = this.props;

    const cityOptions = cities
      .filter(city => city.visibility)
      .map(city => ({ key: city.name, value: city.name, text: city.name }));

    let services;
    let loading;

    if (serviceProviders === undefined) {
      loading = (
        <div className="loading">
          <Loader active />
        </div>
      );
    }

    if (serviceProviders !== undefined) {
      serviceProviders[customerType].sort((a, b) => (b.name > a.name ? -1 : 1));

      if (serviceProviders[customerType].length > 0) {
        services = (
          <div className="list">
            {serviceProviders[customerType].map(serviceProvider => (
              <div key={serviceProvider.id} className="service-provider">
                <div className="logo">
                  <img
                    alt="logo"
                    crossOrigin="anonymous"
                    ref={ref => {
                      this[`img_${serviceProvider.id}`] = ref;
                    }}
                    onLoad={() => this.colorPick(`img_${serviceProvider.id}`)}
                    src={`${config.api}/file?q=id:${serviceProvider.logo}&format=image`}
                  />
                </div>
                <div style={serviceProvider.open ? {} : { display: 'none' }}>
                  <div className="description">
                    {serviceProvider.description &&
                      (
                        serviceProvider.description.find(
                          description =>
                            description.language.indexOf(this.isoToLanguage(userlang)) >= 0,
                        ) || {}
                      ).description}
                  </div>
                  {serviceProvider.offered_services && (
                    <div className="categories">
                      {serviceProvider.offered_services.indexOf('internet') >= 0 && (
                        <div className="category">
                          <Icon name="globe" size="large" />
                          Internet
                        </div>
                      )}
                      {serviceProvider.offered_services.indexOf('voip') >= 0 && (
                        <div className="category">
                          <Icon name="phone" size="large" />
                          VoIP
                        </div>
                      )}
                      {serviceProvider.offered_services.indexOf('iptv') >= 0 && (
                        <div className="category">
                          <Icon name="tv" size="large" />
                          TV
                        </div>
                      )}
                      {serviceProvider.offered_services.indexOf('it') >= 0 && (
                        <div className="category">
                          <Icon name="server" size="large" />
                          IT
                        </div>
                      )}
                      {serviceProvider.offered_services.indexOf('security') >= 0 && (
                        <div className="category">
                          <Icon name="shield" size="large" />
                          Security
                        </div>
                      )}
                    </div>
                  )}
                  <div className="url">
                    <a target="_blank" rel="noopener noreferrer" href={serviceProvider.url}>
                      {serviceProvider.url && this.formatURL(serviceProvider.url, 20)}
                      &nbsp;
                      <Icon name="external" />
                    </a>
                  </div>
                </div>
                <div
                  className="learn-more"
                  onClick={() => this.toggleServiceProvider(customerType, serviceProvider.id)}
                >
                  {formatMessage(messages.learn_more)}{' '}
                  <Icon name={serviceProvider.open ? 'chevron up' : 'chevron down'} />
                </div>
                <div
                  className="triangle top"
                  style={{
                    backgroundColor:
                      colorList &&
                      colorList[`img_${serviceProvider.id}`] &&
                      colorList[`img_${serviceProvider.id}`],
                  }}
                ></div>
                <div
                  className="triangle bottom"
                  style={{
                    backgroundColor:
                      colorList &&
                      colorList[`img_${serviceProvider.id}`] &&
                      colorList[`img_${serviceProvider.id}`],
                  }}
                ></div>
              </div>
            ))}
          </div>
        );
      } else {
        services = (
          <div className="noservice">
            {customerType === 'Residential'
              ? 'Derzeit noch kein Internet Service Provider für Privatkunden verfügbar.'
              : 'Derzeit noch kein Internet Service Provider für Businesskunden verfügbar.'}
          </div>
        );
      }
    }

    return (
      <div className="openaccess">
        <div className="slide dark slide1">
          <div className="content">
            <div className="title">
              <h2>ULTIMATIVE WAHL DER SERVICES</h2>
            </div>
            <div className="description">
              <p>
                Bei LandConnect kannst Du den GlasfaserDirekt Anschluss bestellen* und ein Service
                aussuchen, das zu Dir passt.
              </p>
              <p>
                Über unsere Open-Access-Plattform kannst Du frei zwischen regionalen und
                überregionalen Anbietern wählen. LandConnect selbst bietet keine Services an.
              </p>
              <p>
                Dadurch hast Du eine große Auswahl von verschiedenen Services – hier findest Du das
                Angebot, das zu Dir passt.
              </p>
              <p>
                Wenn Du in einem Ausbaugebiet von LandConnect bist, kannst Du über den Button
                VERFÜGBARKEIT PRÜFEN deinen Glasfaseranschluss bestellen.
              </p>
              <p style={{ fontSize: '12px' }}>
                *falls LandConnect in Deiner Region selbst ausbaut.
              </p>
              <Divider hidden />
              <Link to={`${config.base_url}coverage`}>
                <Button className="gradient">
                  {formatMessage(messages.navigation_get_connected)}
                </Button>
              </Link>
            </div>
          </div>

          <span
            onClick={this.scrollDown}
            onTouchStart={this.scrollDown}
            className="down-chevron-button"
          >
            <img alt="" src={chevron} />
            <span>Anbieter entdecken</span>
          </span>
        </div>

        <div className="dark slide2">
          <div className="content">
            <div className="title">
              <h2>UNSERE SERVICE PROVIDER...</h2>
            </div>
            <div className="description">
              <p>...bieten eine Vielzahl von Möglichkeiten - für jeden das passende Service!</p>
            </div>
            <Divider hidden />

            {!selectedPremises && (
              <React.Fragment>
                <label style={{ marginRight: 20, fontWeight: 500 }}>Bitte Ort auswählen</label>
                <Select
                  placeholder="Bitte Ort auswählen"
                  options={cityOptions}
                  defaultValue={selectedCity}
                  onChange={this.handleCityChange}
                />
                <Divider hidden />
              </React.Fragment>
            )}
            {selectedPremises && (
              <React.Fragment>
                <label style={{ marginRight: 20 }}>
                  Folgende Internet Service Provider sind in Deiner Region verfügbar:{' '}
                  <span style={{ fontWeight: 500 }}>{selectedPremises.address}</span>
                </label>
                <Divider hidden />
              </React.Fragment>
            )}

            <div className="tabs">
              <div
                className={`tab ${customerType === 'Residential' ? ' selected' : ''}`}
                onClick={() => this.handleCustomerTypeChange('Residential')}
              >
                Privatkunden
              </div>
              <div
                className={`tab ${customerType === 'Business' ? ' selected' : ''}`}
                onClick={() => this.handleCustomerTypeChange('Business')}
              >
                Geschäftskunden
              </div>
            </div>
          </div>

          <div className="service-providers-wrapper">
            <div className="service-providers">
              {services}
              {loading}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  init: state.init,
  userlang: state.userlang,
  selectedCity: state.selectedCity,
  selectedPremises: state.selectedPremises,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedCity,
    },
    dispatch,
  );

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FunctionalWrapper));
