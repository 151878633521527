import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { initialStyle, enterStyle, exitStyle } from './animation';

import './slide.css';
import messages from '../../../messages';

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialStyle,
    };
  }

  componentDidMount() {
    // The entering animation is delayed by 400ms
    // after the component mount
    setTimeout(() => {
      this.setState({
        ...enterStyle,
      });
    }, 400);
  }

  componentDidUpdate(prevProps) {
    const { onGoingSlideChange } = this.props;

    if (prevProps.onGoingSlideChange !== onGoingSlideChange && onGoingSlideChange) {
      this.setState({
        ...exitStyle,
      });
    }
  }

  render() {
    const { background, titleLine1, titleLine2, description, button } = this.state;
    const {
      intl: { formatMessage },
      config,
    } = this.props;

    return (
      <div style={background} className="slide background-5">
        <div className="content">
          <div className="title">
            <div style={titleLine1}>GRENZENLOSE</div>
            <div style={titleLine2}>VERBUNDENHEIT</div>
          </div>
          <div className="description" style={description}>
            <p>
              Liebe kennt keine Grenzen und dank LandConnect auch keine Verzögerungen mehr. Mit der
              GlasfaserDirekt-Verbindung kannst du weltweit besondere Momente in HD mit deinen
              Liebsten teilen, ganz ohne nervige „Aussetzer“, lästiges Ruckeln wegen gedrosseltem
              Upload.
            </p>
          </div>
          <div style={button} className="buttons">
            <Link to={`${config.base_url}full-fibre`}>
              <Button className="primary">{formatMessage(messages.learn_more)}</Button>
            </Link>
            <Link to={`${config.base_url}coverage`}>
              <Button className="gradient">
                {formatMessage(messages.navigation_get_connected)}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  onGoingSlideChange: PropTypes.bool,
};

Slide.defaultProps = {
  onGoingSlideChange: false,
};

export default injectIntl(Slide);
