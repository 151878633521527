import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntlProvider } from 'react-intl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useFetch from 'use-http';
import { Navigation, Footer } from 'portal-components';

import getRoutes from './routes';
import menuItems from './menu_data';
import { leftLinks, rightLinks } from './footer_data';

import LoadingSpinner from '../../components/LoadingSpinner';
import { CookieBanner, injectGAScript } from '../../components/CookieBanner';

import { loadLang } from '../../actions/lang.actions';
import { userLang } from '../../actions/userLang.actions';
import { requestLogout } from '../../actions/login.actions';
import { toggleCustomerType } from '../../actions/customerType.actions';
import { requestShowSpinner, requestHideSpinner } from '../../actions/spinner.actions';

import lightLogo from '../../assets/logo-light.png';
import darkLogo from '../../assets/logo-dark.png';

import './App.css';

import ScrollToTop from '../../components/ScrollToTop';

const App = props => {
  const {
    init,
    config,
    release,
    userLang,
    loadLang,
    languages,
    objectGroups,
    customerType,
    selectedCity,
    requestLogout,
    requestShowSpinner,
    toggleCustomerType,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // INITIALIZE fetch to perform requests
  const { get, response } = useFetch('./');

  // DEFINE the local states
  const [locale] = useState(config.languages[0].id);
  const [finalMessages, setFinalMessages] = useState(languages[locale]);

  const [homepageFirstSlide, setHomepageFirstSlide] = useState(true);
  const [ongoingCoverageProcess, setOngoingCoverageProcess] = useState(
    localStorage.getItem('definition_key') && localStorage.getItem('business_key'),
  );

  // GET the messages of a certain language
  const getMessages = async () => {
    await get(`compiled-lang/${locale}.json`);

    if (response.ok) {
      loadLang({ [locale]: response.data });
    }
  };

  // ON component MOUNT
  useEffect(() => {
    // INJECT on mount the tracking script if users already gave the cookie consent
    if (localStorage.getItem('consent')) {
      injectGAScript();
    }

    // COMPARE browser and config languages
    config.languages.forEach(el => {
      if (navigator.language.substring(0, 2) === el.id) {
        userLang(el.id);
      } else {
        userLang(config.languages[0].id);
      }
    });

    // LOAD the messages
    getMessages();
  }, []);

  // SET the final messages
  useEffect(() => {
    if (Object.keys(languages).length > 0 && locale) {
      // setFinalMessages(override(finalMessages, languages[locale], true));
      setFinalMessages({ ...finalMessages, ...languages[locale] });
    }
  }, [languages, locale]);

  // LOGOUT users
  const handleLogoutClick = () => {
    requestShowSpinner();
    requestLogout();

    sessionStorage.removeItem('api_key');
    navigate(config.base_url);
  };

  // SET the customer type according to user selection
  const handleCustomerTypeChange = (event, data) => {
    // SET customer type on Redux store
    toggleCustomerType(data.name);
    // SET toggled customer type on local storage
    localStorage.setItem('customerType', data.name);
  };

  const updateHomepageFirstSlide = () => {
    setHomepageFirstSlide(false);
  };

  const updateCoverageOngoingProcess = newValue => {
    setOngoingCoverageProcess(newValue);
  };

  // DEFINE the menu items
  let items = menuItems;
  // Change the login menu item to logout for logged-in users
  if (init.username) {
    items = items.map(item =>
      item.id !== 'login'
        ? item
        : {
            ...item,
            message: 'logout',
            action: handleLogoutClick,
          },
    );
  }
  // Filter the items that have to be shown only when innet
  items = items.filter(item => !(item.innet && init.autologin_object === null));

  // SHOW a loading spinner until data is available
  if (!init || !init.role || !finalMessages) {
    return <LoadingSpinner />;
  }

  // DEFINE the properties to pass to the routes
  let routeProps = {
    init,
    config,
    locale,
    cities: objectGroups,
    location: {
      ...location,
      query: Object.fromEntries([...searchParams]),
    },
  };

  routeProps =
    init.role !== 'autologin'
      ? {
          ...routeProps,
          homepageFirstSlide,
          baseURL: config.base_url,
          updateHomepageFirstSlide: updateHomepageFirstSlide,
          updateCoverageOngoingProcess: updateCoverageOngoingProcess,
        }
      : routeProps;

  // DEFINE whether the customer type switch has to be shown in the top navigation bar
  const customerTypeSwitchStatus = ['services']
    .map(item => `${config.base_url}${item}`)
    .reduce((acc, cur) => {
      // When accessed by the Personal Area, the Services page has no customer type switch
      // since users can order only services of their customer type
      if (
        cur === `${config.base_url}services` &&
        location.search.indexOf('object=') >= 0 &&
        location.search.indexOf('object_group=') >= 0
      ) {
        return false;
      }

      return acc || cur === location.pathname;
    }, false);

  // DEFINE when the top navigation bar is transparent
  const transparent =
    location.pathname === config.base_url ||
    location.pathname === `${config.base_url}full-fibre` ||
    location.pathname === `${config.base_url}residential` ||
    location.pathname === `${config.base_url}business` ||
    location.pathname === `${config.base_url}offers` ||
    location.pathname === `${config.base_url}openaccess` ||
    location.pathname === `${config.base_url}news` ||
    (location.pathname === `${config.base_url}coverage` && !ongoingCoverageProcess);
  // DEFINE when the top navigation bar has a shadow
  const shadow = location.pathname !== config.base_url && transparent;
  // DEFINE when the style (dark or light) of the LandConnect logo
  const logoURL =
    location.pathname === config.base_url ||
    location.pathname === `${config.base_url}full-fibre` ||
    location.pathname === `${config.base_url}residential` ||
    location.pathname === `${config.base_url}business` ||
    location.pathname === `${config.base_url}offers` ||
    location.pathname === `${config.base_url}openaccess` ||
    location.pathname === `${config.base_url}news` ||
    (location.pathname === `${config.base_url}coverage` && !ongoingCoverageProcess)
      ? lightLogo
      : darkLogo;

  return (
    <IntlProvider locale={locale} messages={finalMessages}>
      <div className="App" id="app_wrapper">
        <ScrollToTop />
        <Navigation
          items={items}
          currentRole={init.role}
          baseURL={config.base_url}
          sticky
          shadow={shadow}
          inverted={transparent}
          transparent={transparent}
          logoURL={logoURL}
          mobileLogoURL={lightLogo}
          residentialBusinessValue={customerTypeSwitchStatus && customerType}
          handleResidentialBusiness={customerTypeSwitchStatus && handleCustomerTypeChange}
          currentPathname={window.location.pathname.split('/').slice(-1)[0]}
        />
        <div className="page-content">{getRoutes(routeProps)}</div>

        {config && release && (
          <Footer
            logo={lightLogo}
            release={release}
            leftLinks={
              selectedCity && selectedCity
                ? leftLinks(config.base_url, selectedCity)
                : leftLinks(config.base_url, '')
            }
            rightLinks={rightLinks(config.base_url)}
            copyrightText={`© ${new Date().getFullYear()} VNEXT AB, Alle Rechte vorbehalten.`}
          />
        )}

        {// The cookie banner is shown when the consent has not been given yet and:
        !localStorage.getItem('consent') && // On every page different from the homepage and the cookie-policy
          ((location.pathname.replace(/\//, '') !== config.base_url.replace(/\//, '') &&
            location.pathname.replace(/\//, '') !==
              `${config.base_url.replace(/\//, '')}cookie-policy`) ||
            // On the homepage when not on the first slide
            (location.pathname === config.base_url && !homepageFirstSlide)) && (
            <CookieBanner baseURL={config.base_url} />
          )}
      </div>
    </IntlProvider>
  );
};

const mapStateToProps = state => ({
  init: state.init,
  languages: state.lang,
  userLang: state.userLang,
  customerType: state.customerType,
  selectedCity: state.selectedCity,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadLang,
      userLang,
      requestLogout,
      requestShowSpinner,
      requestHideSpinner,
      toggleCustomerType,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
