import { defineMessages } from 'react-intl';

export default defineMessages({
  learn_more: {
    id: 'global.learn_more',
    defaultMessage: 'Learn more',
    description: 'global, learn more',
  },
  navigation_full_fibre: {
    id: 'navigation.full_fibre',
    defaultMessage: 'Full fibre',
    description: 'Navigation item: full fibre',
  },
  navigation_residential: {
    id: 'navigation.residential',
    defaultMessage: 'Residential',
    description: 'Navigation item: residential',
  },
  navigation_openaccess: {
    id: 'navigation.openaccess',
    defaultMessage: 'Services providers',
    description: 'Navigation item: open access',
  },
  navigation_business: {
    id: 'navigation.business',
    defaultMessage: 'Business',
    description: 'Navigation item: business',
  },
  navigation_get_connected: {
    id: 'navigation.get_connected',
    defaultMessage: 'Check my availability',
    description: 'Navigation item: get connected',
  },
  navigation_about_us: {
    id: 'navigation.about_us',
    defaultMessage: 'About us',
    description: 'Navigation item: about us',
  },
  yes: {
    id: 'global.yes',
    defaultMessage: 'Yes',
    description: 'global, Yes',
  },
  no: {
    id: 'global.no',
    defaultMessage: 'No',
    description: 'global, No',
  },
  next: {
    id: 'global.next',
    defaultMessage: 'Next',
    description: 'Label, next',
  },
  back: {
    id: 'global.back',
    defaultMessage: 'Back',
    description: 'Label, back',
  },
  login: {
    id: 'global.login',
    defaultMessage: 'login',
    description: 'Label, login',
  },
  loading: {
    id: 'global.loading',
    defaultMessage: 'Loading',
    description: 'Label, loading',
  },
});
