import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { Grid, Button } from 'semantic-ui-react';
import { useResponsive } from 'portal-components';

import messages from '../../messages';
import contentData from '../../content/pages/full-fibre.json';

import './style.css';

const FullFibrePage = props => {
  const { slides } = contentData;
  const {
    config,
    intl: { formatMessage },
  } = props;

  // Get window size
  const { isMobile } = useResponsive();

  return (
    <div className="fullfibre">
      {slides.map((slide, index) => {
        const className = `slide ${
          // Even slides are dark while odd ones are light
          index % 2 === 0 ? 'dark' : 'light'
        }`;

        // Set the background according to the window size
        const style = {
          backgroundImage: `url(${isMobile ? slide.background_mobile : slide.background})`,
        };

        return index === 0 ? (
          // First slide with background image
          <div key={index} style={style} className="slide slide1 dark">
            <div className="content">
              <div className="title">
                <h2>{slide.title}</h2>
              </div>
              <div className="description">
                <ReactMarkdown children={slide.description} />
                {slide.check_my_availability_button && (
                  <div className="buttons">
                    <Link to={`${config.base_url}coverage`}>
                      <Button className="gradient">
                        {formatMessage(messages.navigation_get_connected)}
                      </Button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          // Other slides with animations
          <div key={index} style={style} className={className}>
            <Grid columns={2} verticalAlign="middle">
              <Grid.Row>
                <Grid.Column tablet={16} computer={8}>
                  <div className="content">
                    <div className="title">
                      <h2>{slide.title}</h2>
                    </div>
                    <div className="description">
                      <ReactMarkdown children={slide.description} />
                      {slide.check_my_availability_button && (
                        <div className="buttons">
                          <Link to={`${config.base_url}coverage`}>
                            <Button className="gradient">
                              {formatMessage(messages.navigation_get_connected)}
                            </Button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column tablet={16} computer={8}>
                  <div className="images">
                    {slide.images &&
                      slide.images.map((image, imageIndex) => (
                        <div key={imageIndex} className={index === 0 ? 'house' : 'house house-vx'}>
                          <img src={image} alt="" />
                        </div>
                      ))}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default injectIntl(FullFibrePage);
