import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { initialStyle, enterStyle, exitStyle } from './animation';

import './slide.css';
import messages from '../../../messages';

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialStyle,
    };
  }

  componentDidMount() {
    // The entering animation is delayed by 400ms
    // after the component mount
    setTimeout(() => {
      this.setState({
        ...enterStyle,
      });
    }, 400);
  }

  componentDidUpdate(prevProps) {
    const { onGoingSlideChange } = this.props;

    if (prevProps.onGoingSlideChange !== onGoingSlideChange && onGoingSlideChange) {
      this.setState({
        ...exitStyle,
      });
    }
  }

  render() {
    const { background, titleLine1, titleLine2, titleLine3, description, button } = this.state;
    const {
      intl: { formatMessage },
      config,
    } = this.props;

    return (
      <div style={background} className="slide background-4">
        <div className="content">
          <div className="title">
            <div style={titleLine1}>NUTZE DEN</div>
            <div style={titleLine2}>TURBOEFFEKT</div>
            <div style={titleLine3}>BEIM E-LEARNING</div>
          </div>
          <div className="description" style={description}>
            <p>
              Cloud-basierter Unterricht und E-Learning verändern unseren Zugang zu Bildung und mit
              LandConnect bekommst du einen GlasfaserDirekten Draht zu den neuesten
              Lerntechnologien. Dank der hohen Uploadgeschwindigkeit unserer symmetrischen
              Anschlüsse, kannst du in Echtzeit mit den Vortragenden und deiner Lerngruppe
              zusammenarbeiten – als ob du persönlich dort wärst.
            </p>
            <p>Nimm Platz – für deinen Jahrgang hat soeben der Unterricht begonnen!</p>
          </div>
          <div style={button} className="buttons">
            <Link to={`${config.base_url}full-fibre`}>
              <Button className="primary">{formatMessage(messages.learn_more)}</Button>
            </Link>
            <Link to={`${config.base_url}coverage`}>
              <Button className="gradient">
                {formatMessage(messages.navigation_get_connected)}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  onGoingSlideChange: PropTypes.bool,
};

Slide.defaultProps = {
  onGoingSlideChange: false,
};

export default injectIntl(Slide);
