import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const WaveProgress = props => {
  const { width, height, percentage } = props;

  const viewBox = `0 0 ${width} ${height}`;

  const path1Length = 1377.003;
  const path1dashOffset = path1Length - (path1Length * percentage) / 100;

  const path2Length = 1379.812;
  let path2dashOffset = path2Length - (path2Length * percentage) / 100;
  // Make the second path slightly longer than the first one
  path2dashOffset += percentage > 0 ? 20 : 0;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className="wave-progress"
      style={path1dashOffset === 0 ? { opacity: 0 } : {}}
    >
      <path
        className="background"
        strokeWidth="2pt"
        d="m 0,18.57 a 785.54,785.54 0 0 0 83.09,-8.36 c 136.86,-21.76 300.86,0 300.86,0 0,0 144.41,21.76 296.34,0 151.93,-21.76 295.84,0 295.84,0 0,0 163.96,21.76 300.85,0 a 807.71,807.71 0 0 1 95.65,-9"
      />
      <path
        className="background"
        strokeWidth="2pt"
        d="M 0,4 C 51,1.67 113.59,3.17 174.15,16.1 311,45.36 475,16.1 475,16.1 c 0,0 144.37,-29.23 296.3,0.03 151.93,29.26 296.34,0 296.34,0 0,0 163.96,-29.25 300.85,0 q 2.07,0.45 4.14,0.87"
      />
      <path
        className="progress"
        strokeWidth="3px"
        d="m 0,18.57 a 785.54,785.54 0 0 0 83.09,-8.36 c 136.86,-21.76 300.86,0 300.86,0 0,0 144.41,21.76 296.34,0 151.93,-21.76 295.84,0 295.84,0 0,0 163.96,21.76 300.85,0 a 807.71,807.71 0 0 1 95.65,-9"
        strokeDasharray={path1Length}
        strokeDashoffset={path1dashOffset}
      />
      <path
        className="progress"
        strokeWidth="3px"
        d="M 0,4 C 51,1.67 113.59,3.17 174.15,16.1 311,45.36 475,16.1 475,16.1 c 0,0 144.37,-29.23 296.3,0.03 151.93,29.26 296.34,0 296.34,0 0,0 163.96,-29.25 300.85,0 q 2.07,0.45 4.14,0.87"
        strokeDasharray={path2Length}
        strokeDashoffset={path2dashOffset}
      />
    </svg>
  );
};

WaveProgress.propTypes = {
  size: PropTypes.number,
  percentage: PropTypes.number,
};

WaveProgress.defaultProps = {
  size: 200,
  percentage: 0,
};

export default WaveProgress;
