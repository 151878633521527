import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Button } from 'semantic-ui-react';
import { initialStyle, enterStyle, exitStyle } from './animation';

import './slide.css';
import messages from '../../../messages';

class Slide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialStyle,
    };
  }

  componentDidMount() {
    // The entering animation is delayed by 400ms
    // after the component mount
    setTimeout(() => {
      this.setState({
        ...enterStyle,
      });
    }, 400);
  }

  componentDidUpdate(prevProps) {
    const { onGoingSlideChange } = this.props;

    if (prevProps.onGoingSlideChange !== onGoingSlideChange && onGoingSlideChange) {
      this.setState({
        ...exitStyle,
      });
    }
  }

  render() {
    const { background, titleLine1, titleLine2, titleLine3, description, button } = this.state;
    const {
      intl: { formatMessage },
      config,
    } = this.props;

    return (
      <div style={background} className="slide background-1">
        <div className="content">
          <div className="title">
            <div style={titleLine1}>HOL DIR JETZT –</div>
            <div style={titleLine2}>GLASFASERDIREKT</div>
            <div style={titleLine3}>BIS IN DEIN HEIM</div>
          </div>
          <div className="description" style={description}>
            <p>
              GlasfaserDirekt von LandConnect verbindet Dich mit der ganzen Welt. Die digitale
              Infrastruktur kommt bis in Dein Heim. GlasfaserDirekt wertet Dein Heim auf, stärkt die
              Region und ist eine Investition in die Zukunft – für Generationen. Vergiss
              Kupferleitungen und die Brückentechnologien von Anbietern, die Dich mit Ihrer Leitung
              voll an sich binden wollen. Bei uns hast Du die Wahl!
            </p>
          </div>

          <div style={button} className="buttons">
            <Link to={`${config.base_url}coverage`}>
              <Button className="gradient">
                {formatMessage(messages.navigation_get_connected)}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

Slide.propTypes = {
  onGoingSlideChange: PropTypes.bool,
};

Slide.defaultProps = {
  onGoingSlideChange: false,
};

export default injectIntl(Slide);
