import React from 'react';
import { Image, Segment, Divider } from 'semantic-ui-react';
import './style.css';

const BrowserMessage = props => {
  return (
    <div className="browser-message">
      <Segment basic textAlign="center">
        <Divider hidden />
        <Image src={props.logo} size="small" centered />
        <Divider hidden />
        <h4>Oops. Looks like you are using an old browser.</h4>
        <p>
          LandConnect führt Sie in die Zukunft, Sie müssen jedoch einen aktualisierten Browser
          verwenden.
          <br />
          Bitte laden Sie einen neuen Browser herunter, um zu sehen, wie die Zukunft aussieht.
        </p>
      </Segment>
    </div>
  );
};

export default BrowserMessage;
