import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const LineProgress = props => {
  const { size, percentage } = props;

  // Enclose circle in a circumscribing square
  const viewBox = `0 0 ${size} ${size}`;

  // Arc length at 100% coverage is the circle circumference
  const dashArray = size;

  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      className="line-progress"
      style={dashOffset === 0 ? { opacity: 0 } : {}}
    >
      <line
        className="background"
        strokeWidth="2pt"
        x1={0}
        y1={size - 10}
        x2={size}
        y2={size - 10}
      />
      <line
        className="progress"
        strokeWidth="5px"
        x1={0}
        y1={size - 10}
        x2={size}
        y2={size - 10}
        strokeDasharray={dashArray}
        strokeDashoffset={size - (size * percentage) / 100}
      />
    </svg>
  );
};

LineProgress.propTypes = {
  size: PropTypes.number,
  percentage: PropTypes.number,
};

LineProgress.defaultProps = {
  size: 200,
  percentage: 0,
};

export default LineProgress;
