import { RECEIVE_SERVICE, RESET_SERVICE } from '../actions/service.actions';

export default (state = {}, { type, service }) => {
  switch (type) {
    case RECEIVE_SERVICE:
      return service;
    case RESET_SERVICE:
      return {};
    default:
      return state;
  }
};
