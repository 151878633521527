import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

// Import common pages from library
import {
  FaqPage,
  ProfilePage,
  RedirectPage,
  MarkdownPage,
  NotFoundPage,
  componentLoader,
  UnauthorizedPage,
  PasswordResetPage,
  CustomerServicesPage,
  RequestPasswordResetPage,
  AccountVerificationPage,
} from 'portal-components';

// Import specific contents
import faqPageContent from '../../content/faqs.json';
import agbContent from '../../content/policies/agb.json';
import agbNeustadtlContent from '../../content/policies/agb-neustadtl.json';
import impressumContent from '../../content/policies/impressum.json';
import cookiePolicyContent from '../../content/policies/cookie-policy.json';
import dataProtectionContent from '../../content/policies/data-protection.json';
import cancellationPolicyContent from '../../content/policies/cancellation-policy.json';

// Portal specific pages
import HomePage from '../HomePage';
import ResidentialPage from '../ResidentialPage';
import BusinessPage from '../BusinessPage';
import OpenAccessPage from '../OpenAccessPage';
import FullFibrePage from '../FullFibrePage';
import LoadingSpinner from '../../components/LoadingSpinner';

const AboutUsPage = lazy(() => componentLoader(() => import('../AboutUsPage'), 3));
const CoveragePage = lazy(() => componentLoader(() => import('../CoveragePage'), 3));
const OffersPage = lazy(() => componentLoader(() => import('../OffersPage'), 3));
const ServiceCatalogPage = lazy(() => componentLoader(() => import('../ServiceCatalogPage'), 3));
const PersonalAreaPage = lazy(() => componentLoader(() => import('../PersonalAreaPage'), 3));
const ContactUsPage = lazy(() => componentLoader(() => import('../ContactUsPage'), 3));
const LoginPage = lazy(() => componentLoader(() => import('../LoginPage'), 3));
const SignupPage = lazy(() => componentLoader(() => import('../SignupPage'), 3));
const SpeedTestPage = lazy(() => componentLoader(() => import('../SpeedTestPage'), 3));
const FinalizeOrderPage = lazy(() => componentLoader(() => import('../FinalizeOrderPage'), 3));
const MyPaymentsPage = lazy(() => componentLoader(() => import('../MyPaymentsPage'), 3));

const getRoutes = initialRouteProps => {
  const { init, ...routeProps } = initialRouteProps;

  const apiKey = init.api_key;
  const username = init.username;
  const autologin = init.role === 'autologin';

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path={'/'} element={<HomePage {...routeProps} />} />
        <Route path={'/about-us'} element={<AboutUsPage {...routeProps} />} />
        <Route path={'/residential'} element={<ResidentialPage {...routeProps} />} />
        <Route path={'/business'} element={<BusinessPage {...routeProps} />} />
        <Route path={'/offers'} element={<OffersPage {...routeProps} />} />
        <Route path={'/full-fibre'} element={<FullFibrePage {...routeProps} />} />
        <Route
          path={'/news'}
          element={
            <RedirectPage
              bgColor="#000000"
              redirectTo="/news"
              disableRedirectFor="http://localhost:"
            />
          }
        />
        <Route path={'/openaccess'} element={<OpenAccessPage {...routeProps} />} />
        <Route path={'/contact-us'} element={<ContactUsPage {...routeProps} />} />
        <Route path={'/faq'} element={<FaqPage {...routeProps} contentData={faqPageContent} />} />
        <Route path={'/services'} element={<ServiceCatalogPage {...routeProps} />} />
        <Route path={'/coverage'} element={<CoveragePage {...routeProps} />} />
        <Route path={'/speed-test'} element={<SpeedTestPage {...routeProps} />} />
        <Route
          path={'/my-services'}
          element={
            <CustomerServicesPage
              {...routeProps}
              apiKey={apiKey}
              username={username}
              autologin={autologin}
              messages={{
                de: {
                  pastServices: 'Vergangene Dienste',
                  currentServices: 'Aktuelle Dienste',
                  'CustomerServicesPage.title': 'Meine Dienste',
                  'CustomerServicesPage.subtitle': 'Sehe hier Deine bestellten Internetdienste an',
                  'Order.toCancelOrSuspenduspend':
                    'Um den Dienst zu stornieren oder auszusetzen, wende Dich bitte an',
                },
              }}
            />
          }
        />
        <Route path={'/my-places'} element={<PersonalAreaPage {...routeProps} />} />
        <Route path={'/payments'} element={<MyPaymentsPage {...routeProps} />} />
        <Route path={'/login'} element={<LoginPage {...routeProps} />} />
        <Route path={'/signup'} element={<SignupPage {...routeProps} />} />
        <Route path={'/finalize-order'} element={<FinalizeOrderPage {...routeProps} />} />
        <Route
          path={'/profile'}
          element={<ProfilePage {...routeProps} apiKey={apiKey} autologin={autologin} />}
        />
        <Route
          path={'/email_verification'}
          element={
            <AccountVerificationPage
              {...routeProps}
              processDefinitionKey="EmailVerificationProcessAustria"
            />
          }
        />
        <Route path={'/customer_password'} element={<PasswordResetPage {...routeProps} />} />
        <Route
          path={'/request-password-reset'}
          element={<RequestPasswordResetPage {...routeProps} />}
        />
        <Route path={'/agb'} element={<MarkdownPage {...routeProps} contentData={agbContent} />} />
        <Route
          path={'/agb-neustadtl'}
          element={<MarkdownPage {...routeProps} contentData={agbNeustadtlContent} />}
        />
        <Route
          path={'/cookie-policy'}
          element={<MarkdownPage {...routeProps} contentData={cookiePolicyContent} />}
        />
        <Route
          path={'/cancellation-policy'}
          element={<MarkdownPage {...routeProps} contentData={cancellationPolicyContent} />}
        />
        <Route
          path={'/impressum'}
          element={<MarkdownPage {...routeProps} contentData={impressumContent} />}
        />
        <Route
          path={'/data-protection'}
          element={<MarkdownPage {...routeProps} contentData={dataProtectionContent} />}
        />
        <Route path={'/unauthorized'} element={<UnauthorizedPage {...routeProps} />} />
        <Route path="*" element={<NotFoundPage {...routeProps} />} />
      </Routes>
    </Suspense>
  );
};

export default getRoutes;
